import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';
import styled from 'react-emotion';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';
import dotenv from 'dotenv'
import { connect } from 'react-redux';

import Layout from '../../components/layout';
import ProductCard from '../../components/product-card';
import Container from '../../components/container';
import searchBackground from '../../../static/images/search-background.jpg';
import shpBackground from '../../../static/images/shopbg.jpg';
import luarBackground from '../../../static/images/shopluar.jpg';

dotenv.config();

// Query result goes to this.props.data
export const query = graphql`
  query {
    allWifi {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
        }
      }
    }
    allReseller {
      edges {
        node {
          productId
          title
          price
          duration
          discount_price
          description
          images{url,alt}
          thumbs{url,alt}
        }
      }
    }
  }
`

class ShopPage extends Component {
  constructor(props) {
    super(props);

    this.handleSearch = this.handleSearch.bind(this);
    this.SearchBar = styled.input`
      border: 1px solid black;
      width: 100%;
      margin: 0;
      padding: 7px 11px; 
      font-size: 1em; 
      line-height: 30px;
      max-width: 600px;
      display: block;
      margin: auto;
    `
    this.Filler = styled.div`
      ${tw`pb-6 md:px-3 w-full md:w-1/2 lg:w-1/3 m-0 flex-grow`};
    `
    this.ProductsContainer = styled.div`
      ${tw`flex flex-wrap items-stretch justify-around mx-auto`}
    `
    this.BlockContainer = styled.div`
      ${tw`mx-auto block`};
    `
    this.LeftColumn = styled.div`
      ${tw`block md:inline-block w-full align-top m-auto`}
    `
    this.SearchContainer = styled.div`
      ${tw`mb-3 flex flex-col`};
      height: 200px;
      
      justify-content: space-evenly;
      background-image: url(${searchBackground});
      background-size: cover;
    `
    this.IndoContainer = styled.div`
    background-image: url(${shpBackground});
    background-position: 47% 0;
    `
    this.LuarContainer = styled.div`
    background-image: url(${luarBackground});
    background-position: 47% 0;
    `
    this.Title = styled.h2`
      font-family: Dosis;
      font-size: 28px;
      font-weight: 600;
      line-height: 41px;
      text-align: center;
      color: #686767;
    `
  }
  
  state = {
    products: [],
    productsLoaded: true,
    search: '',
    category: ''
  };

  componentDidMount() {
    const referralCode = this.props.location ? queryString.parse(this.props.location.search).ref_id : '';
    if (referralCode) {
      this.props.changeReferralCode(referralCode);
    }
    // ReactPixel.init('1231110577036563')
    ReactPixel.pageView()
    console.log(this.props.toastMessage, this.props.toastMethod);
    if (this.props.showProductToast) {
      switch (this.props.toastMethod) {
        case 'info':
          toast.info(this.props.toastMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
          break;
        
        case 'warning':
          toast.warning(this.props.toastMessage, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          });
          break;
        
        default:
          break;
      }

      this.props.delToast();
    }

    if (!this.props.category) {
      navigate('/');
    } else {
      this.setState({ products: this.props.products });
    }

    
    console.log('mount')
  }

  handleSearch(event) {
    let products = this.props.products
    products = products.filter((product) => {
      return product.title.toLowerCase().indexOf(
        event.target.value.toLowerCase()) !== -1;
    });
    this.setState({
      search: event.target.value,
      products
    });
  }

  render() {
    console.log('render')

    const Filler = this.Filler
    const FillerContainer = this.state.productsLoaded ? <Filler /> : <span></span>;
    const ProductsContainer = this.ProductsContainer
    const BlockContainer = this.BlockContainer
    const LeftColumn = this.LeftColumn
    const SearchContainer = this.SearchContainer
    const SearchBar = this.SearchBar;
    const Title = this.Title;

    const productCards = this.state.products.map((product) => (
      <ProductCard
        key={product.productId}
        id={product.productId}
        image={product.thumbs[0]}
        productName={product.title}
        discountPrice={product.discount_price}
        currentPrice={product.price}
        productDetail={product}
        type={this.props.category}
        slug={product.slug}
      />
    ));
    
    return (
      <Layout>
        { this.props.category == 'internetindonesia' ? 
        <div className="banner has-hover" id="banner-1755584755"> <this.IndoContainer className="bg fill bg-fill bg-loaded">
          <div className="banner-layers container">
            <div id="text-box-2059938369" className="text-box banner-layer x50 md-x50 lg-x50 y70 md-y70 lg-y70 res-text">
              <div className="text-box-content text dark">
                <div className="text-inner text-center">
                  <h1 className="uppercase"><span style={{fontSize:"140%"}}><strong>MUDAH</strong></span></h1>
                  <h3>Buat internetan seru, dimanapun!</h3>
                </div>
              </div>
            </div>
          </div>
        </this.IndoContainer> 
        </div> : (
        this.props.category == 'internetluarnegeri' ? 
        <div className="banner has-hover" style={{height:"100vh"}}> <this.LuarContainer className="bg fill bg-fill bg-loaded">
          <div className="container" style={{display:'flex',height:'100%',alignItems:'center'}}>
            <div style={{padding: '20px 80px 20px 20px'}}>
              <img width="816" height="424" src="https://travelwifi.id/wp-content/uploads/2021/11/Headline-wifi.png" className="attachment-large size-large" alt="" loading="lazy" srcset="https://travelwifi.id/wp-content/uploads/2021/11/Headline-wifi.png 816w, https://travelwifi.id/wp-content/uploads/2021/11/Headline-wifi-300x156.png 300w, https://travelwifi.id/wp-content/uploads/2021/11/Headline-wifi-768x399.png 768w, https://travelwifi.id/wp-content/uploads/2021/11/Headline-wifi-600x312.png 600w" sizes="(max-width: 816px) 100vw, 816px" />
            </div>
            <div style={{padding: '20px'}}>
              <img width="170" height="313" src="https://travelwifi.id/wp-content/uploads/2021/10/Paydaypromo_Modem.png" className="attachment-large size-large" alt="" loading="lazy" srcset="https://travelwifi.id/wp-content/uploads/2021/10/Paydaypromo_Modem.png 170w, https://travelwifi.id/wp-content/uploads/2021/10/Paydaypromo_Modem-163x300.png 163w" sizes="(max-width: 170px) 100vw, 170px" />
            </div>
          </div>
        </this.LuarContainer> </div> :
        <SearchContainer>
          <div
            style={{
              textAlign: 'center',
              fontFamily: 'Dosis',
              fontWeight: '600'
            }}
          >
            
          </div>
          <Container>
            
          </Container>
          </SearchContainer>
        )}
        <Container>
          { this.props.category == 'internetindonesia' || this.props.category == 'internetluarnegeri' ? 
          <div style={{marginBottom: '40px'}}>
            <Title style={{color:'#000', marginBottom:'10px'}} >Pilih <span style={{color:'#8f14cc'}}>paket internet</span> kamu</Title>
            <p className="text-center"><span style={{color:"#000000"}}>Mau beli atau cukup mau sewa? Tinggal pilih suka-suka!</span></p>
          </div> :
          this.props.category == 'reseller' ? 
          <div id="text-375611856" className="text">
            <h1 style={{textAlign:"center"}}>TOP UP<br />
              <span style={{color:"#000",fontSize:"130%"}}>
                <span style={{color:"#8f14cc"}}>SALDO RESELLER</span>
              </span>
            </h1>
            <p style={{textAlign:"center"}}>
              <strong>Disini kamu bisa Top-Up saldo kamu jika kehabisan! Biar makin lancar jualannya!</strong>
            </p>
          </div> :
          <Title>Jelajahi Semua Paket Internet Kami</Title> 
          }
          <LeftColumn>
            <BlockContainer>
              <ProductsContainer>
                {productCards}
                {FillerContainer}
                {FillerContainer}
              </ProductsContainer>
            </BlockContainer>
          </LeftColumn>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    showProductToast: state.showProductToast,
    toastMessage: state.toastMessage,
    toastMethod: state.toastMethod
  }
}

const mapDispatchToProps = dispatch => {
  return {
    delToast: () => dispatch({ type: `DEL_TOAST` }),
    changeReferralCode: (code) => dispatch({ type: 'CHANGE_REFERRAL_CODE', code })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);